<div class="chat-button" [class.open]="isChatOpen">
    <button class="chat-toggle" (click)="toggleChat()">
        <img
            class="ct-icon"
            [class.close]="!isChatOpen"
            [class.open]="isChatOpen"
            [src]="isChatOpen ? '/assets/images/close.svg' : '/assets/images/chat-support.svg'"
            alt=""
        >
    </button>
</div>

<div class="chat-popup" [class.active]="isChatOpen">
    <the-chat *ngIf="chatId" class="the-chat" [chatId]="chatId" [clientData]=""></the-chat>
    <chat-welcome *ngIf="!chatId" class="chat-welcome"></chat-welcome>

    <button class="cp-close" (click)="isChatOpen = false">
        <img class="cp-close-icon" [class.active]="!chatId" src="/assets/images/close.svg" alt="">
    </button>
</div>
