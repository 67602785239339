import { Injectable } from "@angular/core";

@Injectable()
export class SoundService {
    private audio: HTMLAudioElement = null;
    private unlocked: boolean = false;

    constructor() {
        this.init();
    }

    private init(): void {
        this.audio = document.createElement("audio");
        document.body.appendChild(this.audio);
        this.unlock = this.unlock.bind(this);
        window.addEventListener("click", this.unlock); // for safari
    }

    private unlock(): void {
        this.play("empty.mp3");
        this.unlocked = true;
        window.removeEventListener("click", this.unlock);
        console.log("audio unlocked");
    }

    private play(filename: string): void {
        if (this.unlocked) {
            this.audio.src = `/assets/audio/${filename}`;
            this.audio.load();
            this.audio.play().catch(err => console.log(err));
        }
    }

    public alertOnMessage(): void {
        this.play("message.wav");
    }

    public alertOnAction(): void {
        this.play("action.mp3");
    }
}
