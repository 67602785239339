import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Socket } from "socket.io-client";
import { cfg } from "src/app/config";
import { IMessageCreate } from "src/app/model/dto/message.create.interface";
import { ILang } from "src/app/model/entities/lang.interface";
import { IMessagesFilter, Message } from "src/app/model/entities/message.entity";
import { ISupportClient } from "src/app/model/entities/support-client.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { AppService } from "../../services/app.service";
import { ChatService } from "../../services/chat.service";
import { MessageRepository } from "../../services/repositories/message.repository";
import { SocketService } from "../../services/socket.service";
import { SoundService } from "../../services/sound.service";

@Component({
    selector: "the-chat",
    templateUrl: "chat.component.html",
    styleUrls: [
        "chat.component.scss",
        "../../../common/styles/forms.scss",
    ],
})
export class ChatComponent implements OnInit, OnDestroy {
    @Input() public clientData: ISupportClient;
    @Input() public chatId: string;
    @ViewChild("mlelement", {static: false}) private mlElementRef: ElementRef;

    public ml: Message[] = [];
    public mlFilter: IMessagesFilter = null;
    public mlReady: boolean = false;
    private mlPart: number = 0;
    private mlChunkLength: number = 30;
    private mlExhausted: boolean = false;
    public mlLoadingMore: boolean = false;
    // new message
    public myMessage: string = "";
    // socket
    private socket: Socket = null;
    // iface
    public over: boolean = false;
    public title: string = '';
    public isScrollable: boolean;

    constructor(
      private readonly appService: AppService,
      private readonly messageRepository: MessageRepository,
      private readonly socketService: SocketService,
      private readonly soundService: SoundService,
      private readonly chatService: ChatService,
    ) {}

    get mlElement(): HTMLElement {return this.mlElementRef?.nativeElement;}
    get mlScrolledToTop(): boolean {return this.mlElement.scrollHeight - this.mlElement.offsetHeight + this.mlElement.scrollTop < 200;}
    get mlCanLoadMore(): boolean {return !this.mlLoadingMore && !this.mlExhausted && this.mlScrolledToTop;}
    get lang(): ILang {return this.appService.lang;}
    get words(): IWords {return this.appService.words;}

    public async ngOnInit(): Promise<void> {
        // await this.initChat();
        await this.initMessages();
        this.initSocket();
    }

    public ngAfterContentInit(): void {
        this.isScrollable = this.mlElement?.clientHeight < this.mlElement?.scrollHeight;
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // if (changes["chat_id"]?.currentValue) {
        //     this.ml = [];
        //     this?.socket?.removeAllListeners();
        //     this?.socket?.on(`message-${this.chat_id}`, message => this.onMessage(message));
        //     await this.initChat()
        //     await this.initMessages()
        //     this.initTitle()
        // }
    }

    public async ngOnDestroy(): Promise<void> {
        // this.socket.emit('inactive-client', this.user.id);
        // this.socketService.disconnect(this.socket);
        //
        // if (this.chat && this.user) {
        //     const messages = await this.messageRepository.markAsRead({
        //         chat_id: this.chat.id,
        //         user_id: this.user.id
        //     });
        //
        //     this.chatService.markRead(messages.length);
        // }
    }

    private initChat() {
        try {
        } catch (err) {
            this.appService.notifyError(String(err));
        }
    }

    private async initMessages(): Promise<void> {
        try {
            this.mlReady = false;
            this.ml = [];
            this.mlPart = 0;
            this.mlFilter = {
                chat_id: this.chatId,
                created_at: new Date(), // prevent including items created after scroll start!
            };
            const chunk = await this.messageRepository.loadChunk(this.mlPart, this.mlChunkLength, "id", -1, this.mlFilter);
            this.ml = chunk.data;
            this.mlExhausted = !chunk.elementsQuantity || this.mlPart + 1 === chunk.pagesQuantity;
            this.mlReady = true;
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    private initSocket(): void {
        this.socket = this.socketService.connect(cfg.wsMessagesPath);
        this.socket.on(`message-${this.chatId}`, message => this.onMessage(message));
        // this.socket.emit('active-client', this.user.id);
    }

    public async onScroll(): Promise<void> {
        try {
            if (this.mlCanLoadMore) {
				this.mlLoadingMore = true;
				this.mlPart++;
                const chunk = await this.messageRepository.loadChunk(this.mlPart, this.mlChunkLength, "id", -1, this.mlFilter);
                this.ml = [...this.ml, ...chunk.data];
                this.mlExhausted = !chunk.elementsQuantity || this.mlPart + 1 === chunk.pagesQuantity;
				this.mlLoadingMore = false;
			}
		} catch (err) {
			this.mlLoadingMore = false;
			this.appService.notifyError(err);
		}
    }

    private async onMessage(message: Message): Promise<void> {
        const newMessage = new Message().build(message);
        this.ml.unshift(newMessage);

        if (newMessage.user_id) {
            this.soundService.alertOnMessage();
        }

        this.mlElement.scrollTo(0, 0);
        // this.messageReceived.emit(newMessage);

        // const messages = await this.messageRepository.markAsRead({
        //     chat_id: this.chat.id,
        //     user_id: this.user.id
        // });

        // this.chatService.markRead(messages.length);
    }

    public onMessageKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
        }
    }

    public async sendMessage(dto: IMessageCreate = null): Promise<void> {
        try {
            // call from file popup, it sends DTO
            if (dto) {
                this.messageRepository.create(dto);
                return;
            }

            // call from main iface
            this.myMessage = this.myMessage.trim();

            if (!this.myMessage.length) {
                return;
            }

            dto = {
                chat_id: this.chatId,
                text: this.myMessage,
            };

            const newMessage: Message = await this.messageRepository.create(dto);
            this.myMessage = "";
            this.chatService.sendMessage(newMessage);
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    public onBackClick() {
        this.chatService.closeChat();
    }
}
