import { Injectable, OnInit } from "@angular/core";
import { Socket } from "socket.io-client";
import { SocketService } from "./socket.service";
import { cfg } from "src/app/config";
import { IUser } from "src/app/model/entities/user.interface";
import { Subject } from "rxjs";
import { Message } from "src/app/model/entities/message.entity";
import { SoundService } from "./sound.service";
import { CookieService } from "./cookie.service";
import { ISupportClient } from "src/app/model/entities/support-client.interface";
import { v4 as uuid } from "uuid";
import { ChatRepository } from "./repositories/chat.repository";

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    // socket
    private socket: Socket = null;
    private messageSubject = new Subject<Message>();
    private markReadSubject = new Subject<number>();
    private _chatId: string;
    private _clientData: ISupportClient | null;

    constructor(
        private soundService: SoundService,
        private socketService: SocketService,
        private readonly cookieService: CookieService,
        private chatRepository: ChatRepository,
    ) {
        this._chatId = this.cookieService.getItem('chat_id');

        const clientData = this.cookieService.getItem('support_client');
        this._clientData = clientData ? JSON.parse(clientData) : null;
    }

    get chatId() { return this._chatId}
    set chatId(v: string) {this._chatId = v}
    get clientData(): ISupportClient | null { return this._clientData; }
    set clientData(v: ISupportClient) { this._clientData = v; }

    // get authenticated(): boolean {return this.authService.authData.value !== null;}
    // get user(): IUser {return this.authService.authData.value.user;}

    public initSocket(): void {
        // if (this.authenticated) {
        //     this.socket = this.socketService.connect(cfg.wsMessagesPath, { user_id: this.user.id });
        //     this.socket.on(`message-${this.user.id}`, message => this.onMessage(message));
        // }
    }

    public killSocket(): void {
        this.socketService.disconnect(this.socket);
    }

    onMessage(message: Message): void {
        this.soundService.alertOnMessage();
        this.sendMessage(message);
    }

    sendMessage(message: Message) {
        this.messageSubject.next(message);
    }

    markRead(count: number) {
        this.markReadSubject.next(count);
    }

    getRead() {
        return this.markReadSubject.asObservable();
    }

    getMessage() {
        return this.messageSubject.asObservable();
    }

    public doSimpleAuth(username: string): ISupportClient {
        const clientData: ISupportClient = {
            uuid: uuid(),
            name: username,
        };

        this.cookieService.setItem('support_client', JSON.stringify(clientData));
        this.clientData = clientData;

        return clientData;
    }

    public async startChat({ name, uuid }: ISupportClient): Promise<void> {
        const chat = await this.chatRepository.create({
            client_name: name,
            client_uuid: uuid,
        });

        this.cookieService.setItem('chat_id', chat.uuid);

        this.chatId = chat.uuid;
    }

    public closeChat() {
        this.chatId = null;
        this.cookieService.removeItem('chat_id');
    }
}
